import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';

export const useBrowserId = defineStore('uuid', () => {
  const uuid = computed(()=>{
    let id = localStorage.getItem('uuid')
    if(!id ){    
        id =  uuidv4() as string;
        localStorage.setItem('uuid',id);
    }
    return id
}) 

  return {
    uuid,
  };
});
