import { request as axios } from '@/util/request';
import { Menu } from '@/pinia/modules/userData';

const API = {
  send_code: '/boss/passport/captcha',
  sms: '/boss/passport/sms',
  password: '/boss/passport/password',
  logout: '/boss/passport/logout',
};

/**
 * 获取 CSRF
 */
async function getCSRF() {
  try {
    await axios.get('/boss/passport/csrf');
  } catch (error) {
    console.log(error);
  }
}

/**
 * 发送验证码
 * @param mobile 手机号
 */
async function sendCode(mobile: string) {
  return await axios.post(API.send_code, { mobile });
}

/**
 * 短信登录
 * @param mobile 手机号
 * @param captcha 验证码
 */
async function loginBySms(mobile: string, captcha: string) {
  return await axios.post(API.sms, { mobile, captcha });
}

/**
 * 密码登录
 * @param mobile 手机号
 * @param password 经过 md5 加密后的密码
 */
async function loginByPassword(mobile: string, password: string) {
  return await axios.post(API.password, { mobile, password });
}

/**
 * 退出登录
 */
async function logout() {
  return await axios.post(API.logout);
}
//获取菜单
export async function getMenu() {
  try {
      const res = await axios.get<Menu[]>('/boss/setting/rule');
      if (res.status === 200) {
          return res.data as Menu[];
      }
      else {
          return []
      }
  } catch (error) {
      console.log(error);
      return []
  }
}
export default logout;
export { sendCode, loginBySms, loginByPassword, logout, getCSRF };
