
import { Component, Vue } from 'vue-property-decorator';
import AppAside from '@/component/layout/app/AppAside.vue';
import AppMain from '@/component/layout/app/AppMain.vue';
import { useMenu } from '@/pinia/modules/menu';
import { useUser } from '@/pinia/modules/userData';
// 注册路由钩子

@Component({
  components: {
    AppAside,
    AppMain,
  },
})
export default class AppLayout extends Vue {
  loading: boolean = false;
  useMenuList = useMenu();
  useCacheList = useUser()

  async created() {
    this.loading = true;
    this.loading = false;
  }
  get menuIsOpen() {
    return this.useMenuList.menuSwich;
  }
  menuOpen() {
    this.useMenuList.menuSwichChange();
  }
  link(data: typeof this.useCacheList.menu[0]) {
    if(this.useCacheList.thisSrc==data.client_path){
      return
    }
    this.$router.push(data.client_path);
  }
  // 顶部菜单关闭
  backLink(menu) {
    // console.log(link);
    this.useCacheList.deleteCache(menu)
    // const menu_item_list = this.useMenuList.menuList;
    // this.useMenuList.deleteMenuItemList(index);

    // if (this.$route.path.indexOf(link) !== -1) {
    //   menu_item_list[index]
    //     ? this.$router.push(menu_item_list[index].link)
    //     : menu_item_list[index - 1]
    //     ? this.$router.push(menu_item_list[index - 1].link)
    //     : this.$router.push('/');
    // }
  }
  get logo() {
    return require('@/asset/img/logo-default.png');
  }
}
