
import { Component, Vue, Watch } from 'vue-property-decorator';
import AppLayout from '@/component/layout/app/index.vue';
import Login from '@/view/passport/login.vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import Speech from 'speak-tts';
import { useGlobalFunction } from './pinia/modules/globalFunction';
import { useUser, findNowMenu } from './pinia/modules/userData';
import { request as axios } from '@/util/request';

@Component({
  components: {
    AppLayout,
    Login,
  },
})
export default class App extends Vue {
  locale: any = zhCN;
  globalFunction = useGlobalFunction();
  User = useUser();
  get layout() {
    const { meta } = this.$route;
    const { layout } = meta as any;
    return layout || 'AppLayout';
  }
  oldata: any;
  resdata: any;
  orderOlddata: any;
  orderResdata: any;
  refundOlddata: any;
  refundResdata: any;
  @Watch('$route')
  globalQuery() {
    this.globalFunction.queue.forEach(funKey => {
      console.log(funKey);

      this.globalFunction.globalFunction?.[funKey]?.();
    });
    this.globalFunction.queue = [];
  }

  async created() {
    if (location.hostname == 'alpha-boss.niulandi.com') {
      console.log(process.env);
      return;
    }
    window.setInterval(() => {
      if (!findNowMenu(useUser().menu, ({ id }) => id == 23)) {
        return;
      }
      if (!this.User.isLogin) return;
      setTimeout(async () => {
        const res: any = await axios.get('/boss/knight/order/exception', {
          params: {
            current: 1,
            limit: 10,
            menu_id: 23,
          },
        });
        this.resdata = res.data;
        if (
          this.oldata != undefined &&
          res.data &&
          res.data?.detail?.[0]?.id != this.oldata?.detail?.[0]?.id
        ) {
          this.openNotification(
            `您有新的异常订单，异常单号为：${res.data.detail[0].code}`,
            '/order/abnormal'
          );
          const url =
            "https://tts.baidu.com/text2audio?cuid=baike&spd=5&lan=ZH&ctp=1&pdt=301&vol=4&rate=32&per=0&tex=' " +
            encodeURI('您有新的异常订单，请及时处理');
          const n = new Audio(url);
          n.src = url;
          n.play(); //播放阅读
        }
        this.oldata = res.data;
      }, 0);
    }, 5000);
    window.setInterval(() => {
      if (!findNowMenu(useUser().menu, ({ id }) => id == 65)) {
        return;
      }
      if (!this.User.isLogin) return;
      setTimeout(async () => {
        const res: any = await axios.get('/boss/factory/order', {
          params: {
            current: 1,
            status: 0,
            factory_id: 0,
            menu_id: 65,
          },
        });
        this.orderResdata = res.data;
        if (
          this.orderOlddata != undefined &&
          res.data &&
          res.data?.detail?.[0]?.id != this.orderOlddata?.detail?.[0]?.id
        ) {
          this.openNotification(
            `您有新的进货订单，进货单号为：${res.data.detail[0].code}`,
            '/factory/purchase'
          );
          const url =
            "https://tts.baidu.com/text2audio?cuid=baike&spd=5&lan=ZH&ctp=1&pdt=301&vol=4&rate=32&per=0&tex=' " +
            encodeURI('您有新的进货订单，请及时处理');
          const n = new Audio(url);
          n.src = url;
          n.play(); //播放阅读
        }
        this.orderOlddata = res.data;
      }, 0);
    }, 5000);
    window.setInterval(() => {
      if (!findNowMenu(useUser().menu, ({ id }) => id == 66)) {
        return;
      }
      if (!this.User.isLogin) return;
      setTimeout(async () => {
        const res: any = await axios.get('/boss/factory/refund', {
          params: {
            current: 1,
            status: 0,
            factory_id: 0,
            menu_id: 66,
          },
        });
        this.refundResdata = res.data;
        if (
          this.refundOlddata != undefined &&
          res.data &&
          res.data?.detail?.[0]?.id != this.refundOlddata?.detail?.[0]?.id
        ) {
          this.openNotification(
            `您有新的退货订单，退货单号为：${res.data.detail[0].code}`,
            '/factory/goodRejected'
          );
          const url =
            "https://tts.baidu.com/text2audio?cuid=baike&spd=5&lan=ZH&ctp=1&pdt=301&vol=4&rate=32&per=0&tex=' " +
            encodeURI('您有新的退货订单，请及时处理');
          const n = new Audio(url);
          n.src = url;
          n.play(); //播放阅读
        }
        this.refundOlddata = res.data;
      }, 0);
    }, 5000);
  }

  openNotification(text: string, path: string) {
    this.$notification.open({
      message: '',
      duration: 100000,
      description: text,
      btn: (h: any) => {
        return h(
          'a-button',
          {
            props: {
              type: 'primary',
              size: 'small',
            },
            on: {
              click: () => {
                this.$notification.close('0');

                window.location.href = path;
                //this.$router.push('/order/list')
              },
            },
            style: 'margin-right:120px',
          },
          '确定'
        );
      },
      onClick: () => {
        console.log('Notification Clicked!');
      },
    });
  }
}
