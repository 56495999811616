import { defineStore } from 'pinia'
import { ref } from 'vue'


export const useMainElement = defineStore('main-element', () => {
    const element = ref<HTMLElement>()
    function setElement(val: HTMLElement) {
        element.value = val
    }
    return { element, setElement }
})