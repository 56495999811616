import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import { useUser } from '@/pinia/modules/userData';
import { watch } from 'vue';
import { message } from 'ant-design-vue';
import { useBrowserId } from '@/pinia/modules/uuId';

//获取Promise中的值的TS类型
export type Unpacked<T> = T extends (...args: any[]) => infer U
  ? U
  : T extends Promise<infer U>
  ? U
  : T;
export type PromiseType<T extends (...args: any[]) => any> = Unpacked<ReturnType<T>>;

//重写ts类型 使其与本系统后端规范相符
type ServiceType = Omit<AxiosInstance, 'get' | 'delete' | 'head' | 'post' | 'put' | 'patch'> & {
  (config: AxiosRequestConfig): AxiosPromise;
  (url: string, config?: AxiosRequestConfig): AxiosPromise;
  request<T = any>(config: AxiosRequestConfig): ServiceReturn<T>;
  get<T = any>(url: string, config?: AxiosRequestConfig): ServiceReturn<T>;
  delete(url: string, config?: AxiosRequestConfig): AxiosPromise;
  head(url: string, config?: AxiosRequestConfig): AxiosPromise;
  post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): ServiceReturn<T>;
  put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): ServiceReturn<T>;
  patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): ServiceReturn<T>;
};
export type ServiceReturn<T = any> = Promise<{
  status: number;
  data?: T;
  message?: string;
  [key: string]: any;
}>;
//检查字符串str结尾是否和endStr一致
function endWith(str, endStr) {
  str = str.trim();
  const d = str.length - endStr.length;
  return d >= 0 && str.lastIndexOf(endStr) == d;
}
// 分页数据接口
export interface PaginationData<T> {
  current: number;
  detail: T[];
  last: number;
  limit: number;
  total: number;
}
export const PaginationDefault: PaginationData<any> = {
  current: 1,
  detail: [],
  last: 1,
  limit: 10,
  total: 0,
};
const { VUE_APP_API: BASE_URL, VUE_APP_CSRF: CSRF } = process.env;
let _Vue;

// 创建 axios 实例
const request = axios.create({
  baseURL: BASE_URL, // API 请求的默认前缀
  withCredentials: true,
}) as ServiceType;

// 异常状态处理
const errorHandler = (error: any) => {
  if (error.response.status === 403) {
    console.debug('CSRF 验证不通过');
  } else {
    return [];
  }
};

// 请求拦截器
request.interceptors.request.use(async config => {
  const  brId= useBrowserId().uuid;
  config.xsrfHeaderName = 'X-CSRF-TOKEN';
  config.xsrfCookieName = CSRF;
  config.headers = {...config.headers,
  'x-device-id':brId
  }
  const url = config?.url || '';  
  const thisUrl = url?.replace(`${config.baseURL}/boss/`, '');
  let stop: undefined | Function;
  try {
    await new Promise((resolve, reject) => {
      //如果已配置menu_id直接放行
      if (config.params?.menu_id) {
        resolve(true);
        return
      }
      if (['profile', 'passport'].includes(thisUrl.split('/')[0])) {
        //此模块相关接口直接放行
        resolve(true);
        return
      }
      //如果是这几个接口直接放行
      //获取菜单、获取csrf、登录、注销
      if (
        [
          'setting/rule',
          'passport/csrf',
          'passport/password',
          'passport/logout',
          'passport/captcha',
          'passport/sms',
        ]
          .map(src => endWith(config.url, src))
          .some(Boolean)
      ) {
        resolve(true);
        return
      }
      //如果能获取到thisMenuID直接放行
      if (useUser()?.thisMenuID) {
        config.params = {
          ...config.params,
          menu_id: useUser()?.thisMenuID,
        };
        resolve(true);
        return
      }

      stop = watch(
        useUser(),
        () => {
          if (useUser()?.thisMenuID) {
            // console.log('放行');
            config.params = {
              ...config.params,
              menu_id: useUser()?.thisMenuID,
            };
            resolve(true);
            
          }
          if (useUser().allMenu.length > 0 && !useUser()?.thisMenuID) {
            reject('您没有接口访问权限');
          }
        },
        {
          immediate: true,
        }
      );
      setTimeout(() => {
        reject('菜单请求超时，请检查网络并刷新页面');
      }, 3000);
    });
  } catch (e: any) {
    message.error(e);
  }
  stop?.();
  return config;
}, errorHandler);

// 响应拦截器
request.interceptors.response.use(response => {
  if (!response.data.data) {
    response.data.data = [];
  }

  // 账号停用
  if (response.data.status === 511) {
    console.debug('账号停用');
  }
  if (response.data.status === 401) {
    if (response.data.message == '登录验证未通过') {
      message.warning('登录已失效，请重新登录');
      const user = useUser();
      user?.setUserData(undefined);
    } else {
      console.debug(response.data.message);
    }
  }
  return response.data;
}, errorHandler);

const VueAxios = {
  install(Vue: any) {
    _Vue = Vue;
    _Vue.prototype.$axios = request;
  },
};
export { request };
export default VueAxios;
