import Vue from 'vue';
import App from './app.vue';
import router from './router';
import {
  Comment,
  Carousel,
  Alert,
  AutoComplete,
  Badge,
  Breadcrumb,
  Button,
  Card,
  Avatar,
  Cascader,
  Checkbox,
  Col,
  Collapse,
  ConfigProvider,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Icon,
  Input,
  InputNumber,
  List,
  Menu,
  Modal,
  Pagination,
  Popover,
  Radio,
  Rate,
  Row,
  Select,
  Spin,
  Steps,
  Switch,
  Table,
  Tabs,
  Tag,
  Tree,
  Tooltip,
  Upload,
  TimePicker,
  Timeline,
  message,
  notification,
  Popconfirm,
  Drawer,
  Empty,
  Skeleton,
} from 'ant-design-vue';

import elColorPicker from 'el-color-picker';
import VueAxios from './util/request';
import './filter';
import VueClipboard from 'vue-clipboard2';
import Print from 'vue-print-nb';
import { createPinia, PiniaVuePlugin } from 'pinia';
import VueCompostionApi from '@vue/composition-api';
import VueBarcode from 'vue-barcode';
import JsonExcel from 'vue-json-excel';
//引入中文文件
import pinia from './pinia/pinia';

Vue.component('vue-barcode', VueBarcode);
Vue.use(VueCompostionApi);
Vue.use(PiniaVuePlugin);
Vue.use(JsonExcel);

Vue.use(Timeline);

Vue.use(Avatar);
Vue.use(Print);
Vue.use(Drawer);
Vue.use(Tree);
Vue.use(Skeleton);
Vue.use(Comment);
Vue.use(Popconfirm);
Vue.use(elColorPicker);
Vue.use(VueClipboard);
Vue.use(Alert);
Vue.use(Empty);
Vue.use(AutoComplete);
Vue.use(Badge);
Vue.use(Breadcrumb);
Vue.use(Button);
Vue.use(Card);
Vue.use(Cascader);
Vue.use(Checkbox);
Vue.use(Col);
Vue.use(Collapse);
Vue.use(ConfigProvider);
Vue.use(DatePicker);
Vue.use(Divider);
Vue.use(Dropdown);
Vue.use(Form);
Vue.use(Icon);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(List);
Vue.use(Menu);
Vue.use(Modal);
Vue.use(Pagination);
Vue.use(Popover);
Vue.use(Radio);
Vue.use(Row);
Vue.use(Select);
Vue.use(Spin);
Vue.use(Steps);
Vue.use(Switch);
Vue.use(Rate);
Vue.use(Table);
Vue.use(Tabs);
Vue.use(Tag);
Vue.use(Tooltip);
Vue.use(Upload);
Vue.use(TimePicker);
Vue.use(TimePicker);
Vue.use(VueAxios);
Vue.use(Carousel);
Vue.config.productionTip = false;

Vue.prototype.$message = message;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$notification = notification;
new Vue({
  pinia,
  router,
  render: h => h(App),
}).$mount('#app');
