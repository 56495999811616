import { defineStore } from 'pinia'
import { ref } from 'vue'
interface MenuListItem {
    name: string;
    link: string;
    componentName?: string;
}

export const useMenu = defineStore('menu', () => {
    const menuList = ref<MenuListItem[]>([])
    const menuSwich = ref<boolean>(true)
    function setMenuList(val: MenuListItem) {
        menuList.value.push(val)
    }
    function deleteMenuItemList(index: any) {
        menuList.value.splice(index, 1)
    }
    function menuSwichChange() {
        menuSwich.value = !menuSwich.value
    }
    return { menuList, menuSwich, setMenuList, deleteMenuItemList, menuSwichChange }
})