import Vue from 'vue';
import VueRouter, {RouteConfig, Route, RouterOptions} from 'vue-router';
// import storage from 'baimahu/storage';
// import { request as axios } from '@/util/request';
import pinia from '@/pinia/pinia';
import {getMenu} from '@/api/passport/login';

// import { usePermission } from '@/pinia/modules/permission';
// const uuidv4 = require('uuid/v4');
const Notfound = () => import('@/view/error/404/NotFound.vue');
const ErrorPermission = () => import('@/view/error/403/ErrorPermission.vue');
const AddAdvertisingPosition = () => import('@/view/operation/add-advertising-position.vue');
const AddCoupon = () => import('@/view/marketing/add-coupon.vue');
const AddCouponSecond = () => import('@/view/marketing/add-coupon-second.vue');
const AddGiftPack = () => import('@/view/marketing/add-gift-pack.vue');
const AddMessage = () => import('@/view/operation/add-message.vue');
const AddPanicFirst = () =>
    import(/* webpackChunkName: "operation" */ '@/view/operation/add-panic-first.vue');
const AddPanicSecond = () =>
    import(/* webpackChunkName: "operation" */ '@/view/operation/add-panic-second.vue');
const AddWareModule = () => import('@/view/operation/add-ware-module.vue');
const AddZoneFirst = () =>
    import(/* webpackChunkName: "operation" */ '@/view/operation/add-zone-first.vue');
const AddZoneSecond = () =>
    import(/* webpackChunkName: "operation" */ '@/view/operation/add-zone-second.vue');
const AdvertisingPosition = () => import('@/view/operation/advertising-position.vue');
const BackgroundImage = () => import('@/view/operation/background-image.vue');
const Coupon = () => import('@/view/marketing/coupon.vue');
const CouponCode = () => import('@/view/marketing/coupon-code.vue');
const Customer = () => import('@/view/operation/pages/Customer/customer.vue');
const CustomerDetail = () =>
    import('@/view/operation/pages/Customer/CustomerDetai/customer-detail.vue');
const CustomerTag = () => import('@/view/operation/pages/Customer/CustomerTags/CustomerTag.vue');
const AddCustomerTag = () =>
    import('@/view/operation/pages/Customer/CustomerTags/AddCustomerTag.vue');
const GiftPack = () => import('@/view/marketing/gift-pack.vue');
const GiftPackCode = () => import('@/view/marketing/gift-pack-code.vue');
const Share = () => import('@/view/marketing/share.vue');
const HomeCategory = () =>
    import(/* webpackChunkName: "operation" */ '@/view/operation/home-category.vue');
const HomeWare = () => import('@/view/operation/home-ware.vue');

const Newbiegift = () => import('@/view/operation/newbiegift.vue');

const Login = () => import('@/view/passport/login.vue');
const MessagePush = () => import('@/view/operation/message-push.vue');
const OperationAddBanner = () =>
    import(/* webpackChunkName: "operation" */ '@/view/operation/add-banner.vue');
const OperationBannerList = () =>
    import(/* webpackChunkName: "operation" */ '@/view/operation/pages/banner/banner.vue');
const OrderAfterSale = () =>
    import(/* webpackChunkName: "order" */ '@/view/order/pages/after-sale/after-sale.vue');
const OrderInvoice = () =>
    import(/* webpackChunkName: "order" */ '@/view/order/pages/invoice/invoice.vue');
const OrderList = () =>
    import(/* webpackChunkName: "order" */ '@/view/order/pages/list/OrderList.vue');
const PanicBuying = () => import('@/view/operation/panic-buying.vue');
const RepositoryManagement = () =>
    import(/* webpackChunkName: "repository" */ '@/view/repository/management.vue');
const RepositoryRegion = () =>
    import(/* webpackChunkName: "repository" */ '@/view/repository/region.vue');
const ServiceConfig = () => import('@/view/operation/service-config.vue');
const SettingAccount = () => import(/* webpackChunkName: "setting" */ '@/view/setting/account.vue');
const SettingCs = () =>
    import(/* webpackChunkName: "setting" */ '@/view/setting/service/CustomerServiceManage.vue');
const SettingExchange = () =>
    import(/* webpackChunkName: "setting" */ '@/view/setting/exchange.vue');

const SettingAuthority = () =>
    import(/* webpackChunkName: "setting" */ '@/view/setting/account-authority.vue');
const SettingTransaction = () =>
    import(/* webpackChunkName: "setting" */ '@/view/setting/transaction.vue');
const SpecialZone = () =>
    import(/* webpackChunkName: "operation" */ '@/view/operation/special-zone.vue');
const WareCategory = () => import(/* webpackChunkName: "ware" */ '@/view/ware/category.vue');
const WareDetail = () => import(/* webpackChunkName: "ware" */ '@/view/ware/detail.vue');
const WareRedact = () => import(/* webpackChunkName: "ware" */ '@/view/ware/redact.vue');
const WareManagement = () => import(/* webpackChunkName: "ware" */ '@/view/ware/management.vue');
const WareParameter = () => import(/* webpackChunkName: "ware" */ '@/view/ware/parameter.vue');
const Welcome = () => import('@/view/welcome/index.vue');
const WelcomePersonalInformation = () =>
    import(/* webpackChunkName: "setting" */ '@/view/welcome/personal-information.vue');
// const EditPassport =()=> import('@/view/passport/EditPassport.vue');
const FinanceOrder = () =>
    import(/* webpackChunkName: "finance" */ '@/view/finance/FinanceOrder.vue');
const FinanceWithdrawal = () =>
    import(/* webpackChunkName: "finance" */ '@/view/finance/financeWithdrawal.vue');
const RiderManagement = () => import('@/view/order/pages/management/management.vue');
const KnightList = () => import('@/view/knight/knightList.vue');
const Abnormal = () => import('@/view/order/pages/abnormal/abnormal.vue');
const Withdrawal = () => import('@/view/knight/withdrawal.vue');
const Customerservice = () => import('@/view/knight/customerservice.vue');
const Knightset = () => import('@/view/knight/knightset.vue');
const Knightdetails = () => import('@/view/knight/knightdetails.vue');
const FactoryManagement = () => import('@/view/factory/management/index.vue');
const FactoryProduct = () => import('@/view/factory/product/FactoryProduct.vue');
const ProductDetail = () => import('@/view/factory/product/productDetail.vue');
const FactoryPurchase = () => import('@/view/factory/purchase/FactoryPurchase.vue');
const PurchaseDetail = () => import('@/view/factory/purchase/purchaseDetail.vue');
const FactoryrGoodsRejected = () => import('@/view/factory/goodRejected/index.vue');
const GoodsRejectedDetail = () => import('@/view/factory/goodRejected/goodRejectedDetail.vue');
const StorageOut = () => import('@/view/storage/out.vue');
const StorageOutDetail = () => import('@/view/storage/outDetail.vue');
const StorageEnter = () => import('@/view/storage/enter.vue');
const StorageEnterDetail = () => import('@/view/storage/enterDetail.vue');
const Versions = () => import('@/view/setting/version.vue');
const Invitingset = () => import('@/view/knight/invitingset.vue');
const Invitingrecord = () => import('@/view/knight/invitingrecord.vue');
const OpenScreenSetting = () => import('@/view/operation/openScreenSetting.vue');
const Feedback = () => import('@/view/operation/pages/feedback/feedback.vue');
const Rights = () => import('@/view/vip/rights.vue');
const Setting = () => import('@/view/vip/setting.vue');
const RightsRedact = () => import('@/view/vip/rightsRedact.vue');
const Stock = () => import('@/view/factory/stock/Stock.vue');
const Channel = () => import('@/view/factory/channel/Channel.vue');
const Warehouse = () => import('@/view/factory/warehouse/Warehouse.vue');
const FactoryBarcode = () => import('@/view/factory/barcode/FactoryBarcode.vue');
const FactoryBarcodeTemplate = () => import('@/view/factory/barcode/barcode-teml.vue');
const FactoryHome = () => import('@/view/factory/home/FactoryHome.vue');
const MarketingPoints = () => import('@/view/marketing/pages/points/MarketingPoints.vue');
const GiftCoupon = () => import('@/view/marketing/pages/gift-coupon/GiftCoupon.vue');
const GiftCouponList = () => import('@/view/marketing/pages/gift-coupon/GiftCouponList.vue');
const GiftCouponCreate = () => import('@/view/marketing/pages/gift-coupon/GiftCouponCreate.vue');
const PointsProduct = () => import('@/view/marketing/pages/points-product/PointsProduct.vue');
const Discount = () => import('@/view/marketing/pages/discount/Discount.vue');
const AddDiscount = () => import('@/view/marketing/pages/discount/AddDiscount.vue');
const EditDiscount = () => import('@/view/marketing/pages/discount/EditDiscount.vue');
const TheHome = () => import('@/view/home/TheHome.vue');
const NewOrderList = () => import('@/view/order/pages/list/TheList.vue');
const WareComment = () => import('@/view/ware/pages/evaluation/WareComment.vue');
const TheConver = () => import('@/view/cs/pages/TheConver.vue');
const TheTogether = () => import('@/view/together/pages/config.vue');
const TheTogetherAdd = () => import('@/view/together/pages/add.vue');
const TheTogetherEdit = () => import('@/view/together/pages/add.vue');

const OperationAdvertising = () =>
    import('@/view/operation/pages/advertising/OperationAdvertising.vue');
const OperationAdvertisingDetail = () => import('@/view/operation/pages/advertising/AdvDetail.vue');
const NewBiepriv = () => import('@/view/operation/pages/newbiepriv/NewBiepriv.vue');

Vue.use(VueRouter);

export const whiteList = [
    // 白名单
    {path: '/', component: Welcome},
    {path: '*', redirect: '/'},
];

export const dynamicRoutes = [
    {
        path: '/ware',
        redirect: '/ware/management',
    },
    {
        path: '/order',
        redirect: '/order/list',
    },
    {
        path: '/repository',
        redirect: '/repository/management',
    },
    {
        path: '/operation',
        redirect: '/operation/banner-list',
    },
    {
        path: '/setting',
        redirect: '/setting/account',
    },
    {
        path: '/marketing',
        redirect: '/marketing/coupon',
    },
    {
        path: '/knight',
        redirect: '/knight/knightList',
    },
    {
        path: '/factory',
        redirect: '/factory/management',
    },
    {
        path: '/storage',
        redirect: '/storage/out',
    },
    /* {
      path: '/rider',
      redirect: '/rider/management'
    }, */
    {
        path: '/home',
        name: 'TheHome',
        component: TheHome,
    },
    {
        path: '/login',
        name: 'Login',
        meta: {ignoreLoginStatus: true},
        component: Login,
    },
    {
        path: '/operation/add-advertising-position',
        name: 'AddAdvertisingPosition',
        component: AddAdvertisingPosition,
        meta: {
            parent: '/operation/advertising-position',
        },
    },
    {
        path: '/operation/add-banner',
        name: 'OperationAddBanner',
        component: OperationAddBanner,
        meta: {
            parent: '/operation/banner-list',
        },
    },
    {
        path: '/operation/newbiepriv',
        name: 'NewBiepriv',
        component: NewBiepriv,
        meta: {
            parent: '/operation/newbiepriv',
        },
    },

    {
        path: '/operation/add-message',
        name: 'AddMessage',
        component: AddMessage,
        meta: {
            parent: '/operation/message-push',
        },
    },
    {
        path: '/operation/add-panic-first',
        name: 'AddPunicFirst',
        component: AddPanicFirst,
        meta: {
            parent: '/operation/panic-buying',
        },
    },
    {
        path: '/operation/add-panic-second',
        name: 'AddPunicSecond',
        component: AddPanicSecond,
        meta: {
            parent: '/operation/panic-buying',
        },
    },
    {
        path: '/operation/add-ware-module',
        name: 'AddWareModule',
        component: AddWareModule,
        meta: {
            parent: '/operation/home-ware',
        },
    },
    // {
    //   path: '/operation/ad',
    //   name: 'OperationAdvertising',
    //   component: OperationAdvertising,
    // },
    {
        path: '/operation/add-zone-first',
        name: 'AddZoneFirst',
        component: AddZoneFirst,
        meta: {
            parent: '/operation/special-zone',
        },
    },
    {
        path: '/operation/add-zone-second',
        name: 'AddZoneSecond',
        component: AddZoneSecond,
        meta: {
            parent: '/operation/special-zone',
        },
    },
    {
        path: '/operation/advertising-position',
        name: 'AdvertisingPosition',
        component: AdvertisingPosition,
    },
    {
        path: '/operation/background-image',
        name: 'BackgroundImage',
        component: BackgroundImage,
    },
    {
        path: '/operation/banner-list',
        name: 'OperationBannerList',
        component: OperationBannerList,
    },
    {
        path: '/marketing/coupon',
        name: 'Coupon',
        component: Coupon,
    },
    {
        path: '/marketing/points',
        name: 'MarketingPoints',
        component: MarketingPoints,
    },
    {
        path: '/marketing/points-product',
        name: 'PointsProduct',
        component: PointsProduct,
    },
    {
        path: '/marketing/add-coupon',
        name: 'AddCoupon',
        component: AddCoupon,
        meta: {
            parent: '/marketing/coupon',
        },
    },
    {
        path: '/marketing/add-coupon-second',
        name: 'AddCouponSecond',
        component: AddCouponSecond,
        meta: {
            parent: '/marketing/coupon',
        },
    },
    {
        path: '/marketing/coupon-code',
        name: 'CouponCode',
        component: CouponCode,
    },
    {
        path: '/marketing/gift-pack',
        name: 'GiftPack',
        component: GiftPack,
    },
    {
        path: '/marketing/add-gift-pack',
        name: 'AddGiftPack',
        component: AddGiftPack,
    },
    {
        path: '/marketing/gift-pack-code',
        name: 'GiftPackCode',
        component: GiftPackCode,
    },
    {
        path: '/marketing/share',
        name: 'Share',
        component: Share,
    },
    {
        path: '/customer',
        name: 'Customer',
        component: Customer,
    },
    {
        path: '/customer/customer-tag',
        name: 'CustomerTag',
        component: CustomerTag,
    },
    {
        path: '/customer/add-customer-tag',
        name: 'AddCustomerTag',
        component: AddCustomerTag,
        meta: {
            parent: '/customer/customer-tag',
        },
    },
    {
        path: '/customer/customer-detail',
        name: 'CustomerDetail',
        component: CustomerDetail,
        meta: {
            parent: '/customer',
        },
    },
    {
        path: '/operation/home-category',
        name: 'HomeCategory',
        component: HomeCategory,
    },
    {
        path: '/operation/home-ware',
        name: 'HomeWare',
        component: HomeWare,
    },
    {
        path: '/operation/newbiegift',
        name: 'Newbiegift',
        component: Newbiegift,
    },
    {
        path: '/operation/message-push',
        name: 'MessagePush',
        component: MessagePush,
    },
    {
        path: '/operation/panic-buying',
        name: 'PanicBuying',
        component: PanicBuying,
    },
    {
        path: '/operation/service-config',
        name: 'ServiceConfig',
        component: ServiceConfig,
    },
    {
        path: '/operation/special-zone',
        name: 'SpecialZone',
        component: SpecialZone,
    },
    {
        path: '/order/after-sale',
        name: 'OrderAfterSale',
        component: OrderAfterSale,
        params: {},
    },
    {
        path: '/order/invoice',
        name: 'OrderInvoice',
        component: OrderInvoice,
    },
    {
        path: '/order/oldlist',
        name: 'OrderList',
        component: OrderList,
    },
    {
        path: '/order/list',
        name: 'NewOrderList',
        component: NewOrderList,
    },
    {
        path: '/order/abnormal',
        name: 'Abnormal',
        component: Abnormal,
    },
    {
        path: '/order/management',
        name: 'RiderManagement',
        component: RiderManagement,
        meta: {
            parent: '/order/list',
        },
    },
    {
        path: '/repository/management',
        name: 'RepositoryManagement',
        component: RepositoryManagement,
    },
    {
        path: '/repository/region',
        name: 'RepositoryRegion',
        component: RepositoryRegion,
    },
    {
        path: '/setting/account',
        name: 'SettingAccount',
        component: SettingAccount,
    },
    {
        path: '/setting/exchange',
        name: 'SettingExchange',
        component: SettingExchange,
    },
    {
        path: '/setting/authority',
        name: 'SettingAuthority',
        component: SettingAuthority,
        meta: {
            parent: '/setting/account',
        },
    },
    {
        path: '/setting/transaction',
        name: 'SettingTransaction',
        meta: {
            is_read: true,
            is_write: false,
            id: 1,
        },
        component: SettingTransaction,
    },
    {
        path: '/setting/cs',
        name: 'SettingCs',
        component: SettingCs,
    },
    {
        path: '/ware/category',
        name: 'WareCategory',
        component: WareCategory,
    },
    {
        path: '/ware/detail',
        name: 'WareDetail',
        component: WareDetail,
        meta: {
            parent: '/ware/management',
        },
    },
    {
        path: '/ware/redact',
        name: 'WareRedact',
        component: WareRedact,
        meta: {
            parent: '/ware/management',
        },
    },
    {
        path: '/ware/management',
        name: 'WareManagement',
        component: WareManagement,
    },
    {
        path: '/ware/parameter',
        name: 'WareParameter',
        component: WareParameter,
    },
    {
        path: '/welcome/personal-information',
        name: 'EditPassport',
        // WelcomePersonalInformation
        component: WelcomePersonalInformation,
    },
    {
        path: '/finance/order',
        name: 'FinanceOrder',
        component: FinanceOrder,
    },
    {
        path: '/finance/FinanceWithdrawal',
        name: 'FinanceWithdrawal',
        component: FinanceWithdrawal,
    },
    {
        path: '/knight/knightList',
        name: 'KnightList',
        component: KnightList,
    },
    {
        path: '/knight/withdrawal',
        name: 'Withdrawal',
        component: Withdrawal,
    },
    {
        path: '/knight/customerservice',
        name: 'Customerservice',
        component: Customerservice,
    },
    {
        path: '/knight/knightset',
        name: 'Knightset',
        component: Knightset,
    },
    {
        path: '/knight/knightdetails',
        name: 'Knightdetails',
        component: Knightdetails,
        meta: {
            parent: '/knight/knightList',
        },
    },
    {
        path: '/factory/management',
        name: 'FactoryManagement',
        component: FactoryManagement,
    },
    {
        path: '/factory/product',
        name: 'FactoryProduct',
        component: FactoryProduct,
    },
    {
        path: '/factory/productDetail',
        name: 'ProductDetail',
        component: ProductDetail,
        meta: {
            parent: '/factory/product',
        },
    },
    {
        path: '/factory/purchase',
        name: 'FactoryPurchase',
        component: FactoryPurchase,
    },
    {
        path: '/factory/purchaseDetail',
        name: 'PurchaseDetail',
        component: PurchaseDetail,
        meta: {
            parent: '/factory/purchase',
        },
    },
    {
        path: '/factory/goodRejected',
        name: 'FactoryGoodRejected',
        component: FactoryrGoodsRejected,
    },
    {
        path: '/factory/goodRejectedDetail',
        name: 'GoodRejectedDetail',
        component: GoodsRejectedDetail,
        meta: {
            parent: '/factory/goodRejected',
        },
    },
    {
        path: '/factory/barcode',
        name: 'FactoryBarcode',
        component: FactoryBarcode,
    },
    {
        path: '/factory/barcode/template',
        name: 'FactoryBarcodeTemplate',
        component: FactoryBarcodeTemplate,
        meta: {
            parent: '/factory/barcode',
        },
    },

    {
        path: '/storage/out',
        name: 'StorageOut',
        component: StorageOut,
    },
    {
        path: '/storage/outDetail',
        name: 'StorageOutDetail',
        component: StorageOutDetail,
        meta: {
            parent: '/storage/out',
        },
    },
    {
        path: '/storage/enter',
        name: 'StorageEnter',
        component: StorageEnter,
    },
    {
        path: '/storage/enterDetail',
        name: 'StorageEnterDetail',
        component: StorageEnterDetail,
        meta: {
            parent: '/storage/enter',
        },
    },
    {
        path: '/setting/appversion',
        name: 'Versions',
        component: Versions,
    },
    {
        path: '/knight/invitingset',
        name: 'Invitingset',
        component: Invitingset,
    },
    {
        path: '/knight/invitingrecord',
        name: 'Invitingrecord',
        component: Invitingrecord,
    },
    {
        path: '/operation/open-screen-setting',
        name: 'OpenScreenSetting',
        component: OpenScreenSetting,
    },
    {
        path: '/operation/feedback',
        name: 'Feedback',
        component: Feedback,
    },
    {
        path: '/vip/rights',
        name: 'Rights',
        component: Rights,
    },
    {
        path: '/vip/setting',
        name: 'Setting',
        component: Setting,
    },
    {
        path: '/vip/rights_redact',
        name: 'RightsRedact',
        component: RightsRedact,
        meta: {
            parent: '/vip/rights',
        },
    },
    {
        path: '/factory/stock',
        name: 'Stock',
        component: Stock,
    },
    {
        path: '/factory/channel',
        name: 'Channel',
        component: Channel,
    },
    {
        path: '/factory/warehouse',
        name: 'Warehouse',
        component: Warehouse,
    },
    {
        path: '/factory/home',
        name: 'FactoryHome',
        component: FactoryHome,
    },
    {
        path: '/marketing/gift-coupon',
        name: 'GiftCoupon',
        component: GiftCoupon,
    },
    {
        path: '/marketing/gift-coupon/info',
        name: 'GiftCouponList',
        component: GiftCouponList,
        meta: {
            parent: '/marketing/gift-coupon',
        },
    },
    {
        path: '/marketing/gift-coupon/create',
        name: 'GiftCouponCreate',
        component: GiftCouponCreate,
        meta: {
            parent: '/marketing/gift-coupon',
        },
    },
    {
        path: '/marketing/discount',
        name: 'Discount',
        component: Discount,
    },
    {
        path: '/marketing/add-discount',
        name: 'AddDiscount',
        component: AddDiscount,
        meta: {
            parent: '/marketing/discount',
        },
    },
    {
        path: '/marketing/edit-discount',
        name: 'EditDiscount',
        component: EditDiscount,
        meta: {
            parent: '/marketing/discount',
        },
    },
    {
        path: '/ware/comment',
        name: 'WareComment',
        component: WareComment,
    },
    {
        path: '/cs/conver',
        name: 'TheConver',
        component: TheConver,
    },
    {
        path: '/marketing/together',
        name: "TheTogether",
        component: TheTogether,
    },
    {
        path: '/marketing/together/add',
        name: "TheTogetherAdd",
        component: TheTogetherAdd,
        meta: {
            parent: '/marketing/together',
        },
    },
    {
        path: '/marketing/together/edit',
        name: "TheTogetherEdit",
        component: TheTogetherAdd,
        meta: {
            parent: '/marketing/together',
        },
    },
    {
        path: '/marketing/together/view',
        name: "TheTogetherView",
        component: TheTogetherAdd,
        meta: {
            parent: '/marketing/together',
        },
    },
    {
        path: '/Notfound',
        name: 'Notfound',
        component: Notfound,
        meta: {
            title: 'Notfound页面不存在',
        },
    },
    {
        path: '/ErrorPermission',
        name: 'ErrorPermission',
        component: ErrorPermission,
        meta: {
            title: 'ErrorPermission 无权限页面',
        },
    },
].map(item => ({
    ...item,
    meta: {
        ...item.meta,
        keepAlive: true,
    },
}));
const routes: Array<RouteConfig> = [
    {path: '/login', component: Login, meta: {ignoreLoginStatus: true}},
    {path: '/', component: Welcome},
    ...dynamicRoutes,
    {
        path: '*',
        redirect: '/Notfound',
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
}) as VueRouter & { options: RouterOptions };

const API = {
    queryAllPrivilege: 'boss/setting/privilege/list',
    queryAccountInfo: '/boss/setting/account',
};

// 路由守卫判断是否登录
router.beforeEach(async (to: Route, from: Route, next: any) => {
    // console.log('to', storage.getItem('profile'));
    const {useUser, findNowMenu} = require('@/pinia/modules/userData');
    const isLogin = useUser(pinia)?.isLogin;
    const userPinia = useUser(pinia);

    if (to.path === '/login') {
        userPinia.setMenu([]);
        next();
        return;
    }

    if (userPinia.allMenu.length == 0) {
        const menus = await getMenu();
        userPinia.setMenu(menus);
    }
    if (!isLogin) {
        userPinia.setUserData(undefined);
        userPinia.setMenu([]);
        next('/login');
        return;
    }

    console.log('已登录', to.path);
    if (['/ErrorPermission', '/', '/welcome/personal-information'].includes(to.path)) {
        next();
        return;
    }
    let toRouteId: number | undefined;
    if (to.meta?.parent) {
        toRouteId = useUser()?.setThisSrc?.(to.meta?.parent);
    } else {
        toRouteId = useUser()?.setThisSrc?.(to.path);
    }
    console.log('thisMenuId', toRouteId);

    //开发环境运行直接访问
    if (process.env.VUE_APP_STAGE === 'dev') {
        next();
        return;
    }
    if (!findNowMenu(userPinia.menu, ({id}) => id == toRouteId)) {
        next('/ErrorPermission');
        return;
    }

    console.log('id:', toRouteId);
    next(undefined);
});

// export function getUserRoutes(permissionList: any = [], allRoutes: any = []) {
//   const user_router_list: any = [];
//   const resRoutes: any = [];
//   // 比较用户权限得到用户有权限的路由
//   permissionList.forEach((item: any) => {
//     item.children.forEach((item2: any) => {
//       user_router_list.push(`/${item2.rule}`);
//     });
//   });
//   allRoutes.forEach((item: any) => {
//     if (user_router_list.indexOf(item.path) !== -1) {
//       resRoutes.push(item);
//     }
//   });
//   return resRoutes;
// }

export default router;

export function useRouter() {
    return router;
}

export function useRoute() {
    return router.currentRoute;
}
