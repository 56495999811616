export const errorMessage = (res: any) => {
  if (res.status !== 410) {
    return true;
  } else {
    return false;
  }
};

const dateFormat = require('bmh/date-format');
/**
 * 时间转换函数
 * @param date 十位数的时间戳
 */
export const formatDate = (date: number) => {
  return dateFormat(new Date(date * 1000), '%Y-%m-%d %H:%M:%S');
};

/**
 * 根据指定数组转换成类 map 结构的对象
 * @param list 数组
 */
export const listToMap = (list: { value: string; label: string }[]) => {
  return list.reduce((map: any, ele: any) => {
    map[ele.value] = ele.label;
    return map;
  }, {});
};
