
// import { Menu } from '@/pinia/modules/userData';
import { Menu as AntMenu, Icon } from 'ant-design-vue';
import Vue from 'vue';
import {useMenu} from "@/pinia/modules/menu";
export interface Menu {
  id: number;
  name: string;
  icon?: string;
  sort: number;
  parent_id: number;
  client_path: string;
  is_data_limit: number;
  is_menu: 0 | 1;
  is_page?: boolean;
  child: Menu[];
  permission?: Menu[];

}
// 深度搜索subMenuArr中id和item.key相等的menu
function deepSearch(subMenuArr: Menu[], key: string | number): Menu | null {
  for (let i = 0; i < subMenuArr.length; i++) {
    if (String(subMenuArr[i].id) == key) {
      return subMenuArr[i];
    } else if (subMenuArr[i].child.length > 0) {
      const menu = deepSearch(subMenuArr[i].child, key);

      if (menu) {
        return menu;
      }
    }
  }
  return null;
}

export default Vue.extend({
  props: {
    subMenuArr: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    isInline: {
      type: Boolean,
      default: true,
    }
  },
  data: () => ({
    selectedKeys: [] as string[],
    openKey: [],
    // selectedValue: [] as Menu[] | null,
  }),
  methods: {
    onSelectedKeysChange(item: { key: number; [key: string]: any }) {
      const { key } = item;
      (this as any).selectedKeys = [key];
      const subMenuArr = (this as any).$props.subMenuArr as Menu[];

      const menu = deepSearch(subMenuArr, key);

      (this as any).$emit('onSelect', menu);
    },
  },
  watch: {
    value: {
      handler(val) {
        (this as any).selectedKeys = val;
      },
      immediate: true,
    },
    //监听selectedKeys变化，触发change事件
    selectedKeys: {
      handler(val) {
        (this as any).$emit('change', val);
        const subMenuArr = (this as any).$props.subMenuArr as Menu[];
        const menu = deepSearch(subMenuArr, val?.[0]);
        console.log(menu?.parent_id)
        if(menu?.parent_id && (this as any).$props.isInline){
          (this as any).openKey = [menu?.parent_id];
        }
        // (this as any).openKey = [keys.pop()];
        // if ((this as any).selectedValue?.id != menu?.id) {
        //   (this as any).selectedValue = menu;
        // }
      },
      immediate: true,
    },
  },
  emits: ['onSelect', 'change'],
  render(h) {
    const subMenuArr = (this as any).$props.subMenuArr as Menu[];
    //根据subMenuArr生成树形菜单（深度优先搜索）
    function deepTraversal(subMenuArr: Menu[]) {
      return subMenuArr.map(item => {
        if (item.child.length > 0) {
          /**
           * <a-subMenu>
           *  <template #title>
           *    <span class="menu-text">
           *     <icon type="item?.icon"
           *       :style="{
                   fontSize: '22px',
                 }" />
           *     {{item.name}}
           *  </span>
           *  </template>
           *    {{deepTraversal(item.child)}}
           * </a-subMenu>
           */
          return h(
            AntMenu.SubMenu,
            {
              scopedSlots: {
                title: () =>
                  h(
                    'span',
                    {
                      class: 'menu-text',
                    },
                    [
                      h(Icon, {
                        props: {
                          type: item?.icon,
                        },
                        style: {
                          fontSize: '15px',
                        },
                      }),
                      item.name,
                    ]
                  ),
              },
              key: item.id,
            },
            deepTraversal(item.child)
          );
        } else {
          return h(
            AntMenu.Item,
            {
              key: item.id,
            },
            [
              h('span', {
                style: { display: 'block', width: '20px', height: '100%', float: 'left' },
              }),
              item.name,
            ]
            // item.name
          );
        }
      });
    }
    return h(AntMenu,{
        props: {
          mode: (this as any).$props.isInline ? 'inline' : 'vertical',
          multiple: false,
          selectedKeys: (this as any).selectedKeys,
          openKeys: this.openKey
        },
        //事件
        on: {
          select: item => (this as any).onSelectedKeysChange(item),
          openChange: keys => {
            const _key = keys.pop();
            this.openKey = [];
            if(_key) this.openKey = [_key];
          }
        },
      },

      deepTraversal(subMenuArr)
    );
  },
} as any);


