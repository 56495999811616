var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.loading)?_c('a-spin',{class:_vm.$style['spin'],attrs:{"spinning":_vm.loading}}):_c('div',{class:_vm.$style['body']},[_c('div',{class:_vm.$style['top']},[_c('div',{class:_vm.$style['topTitle'],style:({ width: _vm.menuIsOpen ? '230px' : '100px' })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.menuIsOpen),expression:"!menuIsOpen"}],class:_vm.$style['logo']},[_c('img',{attrs:{"src":_vm.logo,"alt":"logo"}})]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.menuIsOpen),expression:"menuIsOpen"}],staticStyle:{"font-weight":"300","color":"#fff","margin":"0 auto","font-size":"20px"}},[_vm._v(" 纽澜地管理后台 ")])]),_c('div',{class:_vm.$style['more'],staticStyle:{"cursor":"pointer"},on:{"click":_vm.menuOpen}},[_c('a-icon',{attrs:{"type":!_vm.menuIsOpen ? 'menu-unfold' : 'menu-fold'}})],1),_c('div',{class:[
        _vm.$style['headerMenu'],
        _vm.menuIsOpen ? [_vm.$style['headerMenuWidthOpen']] : [_vm.$style['headerMenuWidthPackUp']],
      ]},_vm._l((_vm.useCacheList.cacheList),function(item,index){return _c('div',{key:item.client_path,class:[
          _vm.$style['headerMenuItem'],
          item.client_path==_vm.useCacheList.thisSrc&&_vm.$style['bg-menu-item']
          // true?[$style['bg-menu-item']]:undefined ,
        ],on:{"click":function($event){return _vm.link(item)}}},[_c('a-icon',{attrs:{"type":"code-sandbox"}}),_c('div',{class:_vm.$style['headerMenuText']},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('a-icon',{class:_vm.$style['closeHeaderMenu'],attrs:{"type":"close"},on:{"click":function($event){$event.stopPropagation();return _vm.backLink(item)}}})],1)}),0)]),_c('div',{staticStyle:{"width":"100%","display":"flex","height":"calc(100% - 50px)"}},[_c('AppAside',{class:_vm.$style['aside'],style:({width:_vm.menuIsOpen?'230px':'100px'})}),_c('AppMain',{staticStyle:{"flex":"1"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }