import { defineStore } from 'pinia'
import { ref } from 'vue'

interface CallBack {
    //礼品券刷新
    queryCouponList: (params?: any) => any;
    getAdvPosition: (params?: any) => any;
    fetchLimitTimeList: (params?: any) => any;
    fetchSpecialZoneData: (params?: any) => any;
    fetchBannerData: (params?: any) => any;
    getHomeWareLis: (params?: any) => any;
    getMessageList: (params?: any) => any;
    fetchWareList: (params?: any) => any;
    getCouponCodeList: (params?: any) => any;
    getCouponList: (params?: any) => any;
    productManagementList: (params?: any) => any;
    //要新增的方法写在这...
}
const initValue = {
    //设置方法默认值
    queryCouponList: () => undefined,
    getAdvPosition: () => undefined,
    fetchLimitTimeList: () => undefined,
    fetchSpecialZoneData: () => undefined,
    fetchBannerData: () => undefined,
    getHomeWareLis: () => undefined,
    getMessageList: () => undefined,
    fetchWareList: () => undefined,
    getCouponCodeList: () => undefined,
    getCouponList: () => undefined,
    productManagementList: () => undefined,


}
type Keys = keyof CallBack

export const useGlobalFunction = defineStore('globalFunction', () => {

    const globalFunction = ref<CallBack>(initValue)
    const queue = ref<string[]>([])
    /**
     * 注册方法
     * @param cal 
     */
    function setGlobalFunction(cal: { [key in Keys]?: (params?: any) => any }) {
        const obj = {}
        // console.log(queue.value);
        Object.keys(cal).forEach(key => {
            obj[key] = (params) => {
                queue.value = Array.from(new Set([
                    ...queue.value,
                    key
                ]))


                cal[key]?.(params)
            }
            return
        })

        globalFunction.value = {
            ...globalFunction.value,
            ...obj
        }
    }
    return {
        queue,
        globalFunction,
        setGlobalFunction
    }
})