import storage from 'baimahu/storage';
import { defineStore } from 'pinia';
import { ref, reactive } from 'vue';
import lodash from 'lodash';
import router from '@/router';
export interface UserData {
  create_time: number;
  delete_time: number;
  id: number;
  mobile: string;
  name: string;
  privilege: string;
  status: number;
  update_time: number;
}
export interface Menu {
  id: number;
  name: string;
  icon?: string;
  sort: number;
  client_path: string;
  is_data_limit: number;
  is_menu: 0 | 1;
  is_page?: boolean;
  child: Menu[];
  permission?: Menu[];
}
export const useUser = defineStore('user', () => {
  const userData = ref<UserData | undefined>(storage.getItem('profile'));
  function setUserData(data: UserData | undefined) {
    userData.value = data;
    isLogin.value = !!data;
  }
  const isLogin = ref(!!storage.getItem('profile'));
  const menu = ref<Menu[]>([]);
  const allMenu = ref<Menu[]>([]);
  const thisMenu = ref<Menu[]>([]);
  const thisSrc = ref<string>('/');
  const thisMenuID = ref<number | string | undefined>();

  const cacheList = ref<(Menu & { componentName?: string })[]>([]);
  function clearCache() {
    cacheList.value = [];
  }
  function setCache(menu: Menu) {
    const has = cacheList.value.find(cache => cache.id == menu.id);
    const component = router.options.routes?.find(route => {
      return route.path == menu?.client_path;
    });
    const componentName = component?.name || component?.component?.name;

    if (!has) {
      cacheList.value = [
        ...cacheList.value,
        {
          ...menu,
          componentName,
        },
      ];
    }
    if (has && !has?.componentName) {
      cacheList.value = cacheList.value.map(item =>
        item.componentName ? item : { ...item, componentName }
      );
    }
  }
  function refreshCache(menu: Menu) {
    cacheList.value = [
      ...cacheList.value.map(item => {
        if (item.id == menu.id) {
          return {
            ...item,
            componentName: undefined,
          };
        }
        return item;
      }),
    ];
  }
  function deleteCache(menu: Menu) {
    const index = cacheList.value.findIndex(({ id }) => id == menu.id) || 0;

    cacheList.value = [...cacheList.value.filter(cache => cache.id != menu.id)];

    if (thisSrc.value == menu?.client_path && index - 1 >= 0) {
      const toSrc = cacheList.value?.[index - 1]?.client_path;
      toSrc && router.push(toSrc);
      setThisSrc(toSrc);
    } else if (thisSrc.value == menu?.client_path && index >= 0) {
      const toSrc = cacheList.value?.[index]?.client_path;
      toSrc && router.push(toSrc);
      setThisSrc(toSrc);
    }

    if (cacheList.value.length == 0) {
      router.push('/');
    }
  }
  function setThisSrc(src) {
    thisSrc.value = src;
    const m = findNowMenu(allMenu.value, url => thisSrc.value == url.client_path);
    if (m) {
      (thisMenu.value = [m]), (thisMenuID.value = m.id);
    } else {
      thisMenu.value = [];
      thisMenuID.value = undefined;
    }
    return thisMenuID.value;
  }
  function setMenu(data: Menu[]) {
    const privilege = userData.value?.privilege?.split(',');
    //遍历data，并且若所有child的is_menu都为0，则将该节点的child置为[]
    function deepTraversal(data: Menu[]) {
      data.forEach(item => {
        if (item.child.length > 0) {
          item.child.sort((a, b) => a.sort - b.sort);
          deepTraversal(item.child);
          if (item.child.every(child => child.is_menu === 0)) {
            item.permission = [...item.child].filter(child => {
              if (privilege?.includes('-1')) return true;
              return privilege?.includes(child.id.toString());
            });
            item.child = [];
            item.is_page = true;
          }
        }
      });
    }
    //遍历data，保留所有child.length>0或者permission.length>0的节点
    function deleteNotNode(data: Menu[]) {
      const result: Menu[] = [];
      data.forEach(item => {
        if (item.child.length > 0) {
          item.child = deleteNotNode(item.child);
          if (item.child.length > 0) {
            result.push(item);
          }
        } else if (item.permission && item.permission?.length > 0) {
          result.push(item);
        }
      });
      return result;
    }
    allMenu.value = lodash.cloneDeep(data);
    deepTraversal(data);
    const result = deleteNotNode([...data]);
    result.forEach(m => {
      switch (m.id) {
        case 1:
          m.icon = 'home';
          break;
        case 2:
          m.icon = 'user';
          break;
        case 3:
          m.icon = 'edit';
          break;
        case 4:
          m.icon = 'line-chart';
          break;
        case 5:
          m.icon = 'global';
          break;
        case 6:
          m.icon = 'database';
          break;
        case 7:
          m.icon = 'car';
          break;
        case 8:
          m.icon = 'sketch';
          break;
        case 9:
          m.icon = 'shop';
          break;
        case 10:
          m.icon = 'cluster';
          break;
        case 11:
          m.icon = 'file';
          break;
        case 12:
          m.icon = 'setting';
          break;
        default:
          break;
      }
    });
    menu.value = result;
    thisMenuID.value =
      findNowMenu(result, url => thisSrc.value == url.client_path)?.id || undefined;
  }
  // //深度查找ids中所有的子节点的id
  // function getChildrenIds(ids: number[]) {
  //   const result: number[] = [...ids];
  //   function deepTraversal(data: Menu[], isInclude = false) {
  //     data.forEach(item => {
  //       if (isInclude) {
  //         result.push(item.id);
  //       }
  //       if (item.child.length > 0) {
  //         deepTraversal(item.child, isInclude || result.includes(item.id));
  //       }
  //     });
  //   }
  //   deepTraversal(allMenu.value);
  //   return result;
  // }
  const permission = ref();
  return {
    /**用户数据 */
    userData,
    setUserData,
    /**是否登录 */
    isLogin,
    /**剔除无法显示的menu后的菜单（主要判断该用户权限是否可以显示该菜单） */
    menu,
    setMenu,
    /**未剔除无法显示的menu的菜单（所有菜单） */
    allMenu,
    /**权限 */
    permission,
    thisMenu,
    /**当前菜单src */
    thisSrc,
    setThisSrc,
    /**当前菜单id */
    thisMenuID,
    /**缓存页面 */
    cacheList,
    setCache,
    clearCache,
    deleteCache,
    refreshCache,
  };
});

//搜索user.menu中与callback符合条件的那一项
export function findNowMenu(menus: Menu[], callback: (menu: Menu) => boolean): Menu | undefined {
  const copy_menus = lodash.cloneDeep(menus);
  let res: undefined | (typeof menus)[0] = undefined;
  for (const m of copy_menus) {
    const isReturn = callback(m);
    if (isReturn) {
      res = m;
      break;
    } else {
      res = findNowMenu(m.child, callback);
      if (res) break;
    }
  }
  return res;
}
